const GB = {
    translation: {
      "Content title": "Hi there!",
      "Content name sentence": "My name is Kirsten Maas.",
      "Content study sentence": "I am currently pursuing a master degree in",
      "Content study": "Data Science",
      "Content update website sentence": "This website will get an update soon.",
      "Content checkout": "Please checkout",
      "Content LinkedIn": "LinkedIn",
      "Content more information": "for more information"
    }
  }

  export default GB;