const NL = {
  translation: {
    "Content title": "Hoi!",
    "Content name sentence": "Ik ben Kirsten Maas.",
    "Content study sentence": "Op dit moment volg ik een master in",
    "Content study": "Data Science",
    "Content update website sentence": "Deze website krijgt binnenkort een update.",
    "Content checkout": "Kijk op",
    "Content LinkedIn": "LinkedIn",
    "Content more information": "voor meer informatie"
  }
}

export default NL;