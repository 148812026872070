import React from 'react';

function Header() {

    return (
        <div className="Header">
        </div>
    );
  }
  
  export default Header;